import { getSecureComUrlCreators } from "common/utils/universal/securecom-urls";
import Either from "data.either";
import Maybe from "data.maybe";
import Task from "data.task";
import { chain, compose, curry } from "ramda";
import { addKeyIfNotNullOrUndefined } from "../../../../universal/object";
import { fold } from "../data.either";
import { addParamsToUrl } from "../urls";

export const NETWORK_ERROR = "NETWORK_ERROR";
export const UNEXPECTED_API_RESPONSE = "UNEXPECTED_API_RESPONSE";
export const UNKNOWN_ERROR = "UNKNOWN_ERROR";
export const INVALID_REQUEST = "INVALID_REQUEST";
export const NOT_AUTHENTICATED = "NOT_AUTHENTICATED";
export const JOB_TIMED_OUT = "JOB_TIMED_OUT";

export const tagNetworkError = (error) => ({ type: NETWORK_ERROR, error });
export const tagUnexpectedApiResponseError = ({ request, response } = {}) => ({
  type: UNEXPECTED_API_RESPONSE,
  request,
  response,
});
export const tagUnknownError = ({ request, response } = {}) => ({
  type: UNKNOWN_ERROR,
  request,
  response,
});
export const tagInvalidRequestError = (errors) => ({
  type: INVALID_REQUEST,
  errors,
});
export const tagNotAuthenticatedError = () => ({ type: NOT_AUTHENTICATED });
export const tagJobTimedOutError = () => ({ type: JOB_TIMED_OUT });

const hasType =
  (type) =>
  (tag = {}) =>
    tag.type === type;

export const isNetworkError = hasType(NETWORK_ERROR);
export const isUnexpectedApiResponseError = hasType(UNEXPECTED_API_RESPONSE);
export const isUnknownError = hasType(UNKNOWN_ERROR);
export const isInvalidRequestError = hasType(INVALID_REQUEST);
export const isNotAuthenticatedError = hasType(NOT_AUTHENTICATED);
export const isJobTimedOutError = hasType(JOB_TIMED_OUT);

const fetchJson =
  (method) =>
  ({
    url,
    authToken = undefined,
    authUserCode,
    params = {},
    headers = {},
    body = undefined,
    ...init
  } = {}) =>
    new Task((reject, resolve) => {
      const useV4 = authToken?.length > 20;
      const request = new Request(
        addParamsToUrl(
          {
            ...params,
            auth_token: authToken === "" || useV4 ? undefined : authToken,
          },
          url
        ),
        {
          ...init,
          method,
          headers: {
            ...headers,
            Accept: "application/json",
            Authorization: useV4 ? `Bearer ${authToken}` : undefined,
            ...addKeyIfNotNullOrUndefined("Authorization-Code", authUserCode),
            "Content-Type": "application/json",
          },
          body: body ? JSON.stringify(body) : undefined,
        }
      );

      fetch(request)
        .then((response) =>
          response
            .json()
            .then(Maybe.of)
            .catch(Maybe.Nothing)
            .then((json) => ({
              status: response.status,
              json,
              response,
              request,
            }))
            .then(response.ok ? Either.of : Either.Left)
            .then(resolve)
        )
        .catch((error) => {
          return reject(tagNetworkError(error));
        });
    });

export const getJson = fetchJson("GET");
export const createJson = fetchJson("POST");
export const updateJson = fetchJson("PUT");
export const patchJson = fetchJson("PATCH");
export const deleteJson = fetchJson("DELETE");

/**
 * Imposes a strict expectation that a result from a `fetchJson` call will succeed.
 * If it does not meet these expectations then the result will be a
 * ```
 Task.rejected({ type: "UNEXPECTED_API_RESPONSE", request: Request, response: Response })
 ```
 */
export const expectSuccess = chain(
  fold(compose(Task.rejected, tagUnexpectedApiResponseError), Task.of)
);

/**
 * Imposes a strict expectation that a result from a `fetchJson` call will succeed with a specific JSON body in the response.
 * If it does not meet this expectation then the result will be a
 * ```
 Task.rejected({ type: "UNEXPECTED_API_RESPONSE", request: Request, response: Response })
 ```
 */
export const expectSuccessWithJsonBody = curry((normalizeJson, task) =>
  expectSuccess(task).chain(({ json, request, response }) =>
    json
      .chain(normalizeJson)
      .map(Task.of)
      .getOrElse(
        Task.rejected(tagUnexpectedApiResponseError({ request, response }))
      )
  )
);

/**
 * Imposes a strict expectation that a result from a `fetchJson` call will either fail with a specific JSON body or succeed
 * If it does not meet these expectations then the result will be a
 * ```
 Task.rejected({ type: "UNEXPECTED_API_RESPONSE", request: Request, response: Response })
 ```
 */
export const expectSuccessOrInvalidRequestErrors = curry(
  (normalizeErrors, task) =>
    task.chain((result) =>
      result.fold(
        ({ status, json, request, response }) =>
          json
            .chain(normalizeErrors)
            .map(tagInvalidRequestError)
            .map(Task.rejected)
            .getOrElse(
              Task.rejected(
                tagUnexpectedApiResponseError({ request, response })
              )
            ),
        Task.of
      )
    )
);

/**
 * Imposes a strict expectation that a result from a `fetchJson` call will either fail with a specific JSON body or succeed with a specific JSON body.
 * If it does not meet these expectations then the result will be a
 * ```
 Task.rejected({ type: "UNEXPECTED_API_RESPONSE", request: Request, response: Response })
 ```
 */
export const expectSuccessWithJsonBodyOrInvalidRequestErrors = curry(
  (normalizeErrors, normalizeJson, task) =>
    expectSuccessOrInvalidRequestErrors(normalizeErrors, task).chain(
      ({ json, request, response }) =>
        json
          .chain(normalizeJson)
          .map(Task.of)
          .getOrElse(
            Task.rejected(tagUnexpectedApiResponseError({ request, response }))
          )
    )
);

const isRefreshBypass = async (response) => {
  const env = process.env?.REACT_APP_SECURECOM_ENV ?? "production";
  const urls = getSecureComUrlCreators({ env });
  const data = await response.json();

  const isExpiredAuth = data?.devMessage === "JWT is expired";
  const isThirdPartyVideoCall = response.url.includes(urls.thirdPartyVideo(""));

  return isThirdPartyVideoCall && !isExpiredAuth;
};
